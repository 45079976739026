import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/index';
import store from './store/index';
import VueCookies from 'vue-cookies';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 
//import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd";
import "vuejs-clipper/dist/vuejs-clipper.css";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueUploadComponent from 'vue-upload-component'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Multiselect from '@vueform/multiselect';
import VueZoomer from 'vue-zoomer';
import { Cropper } from 'vue-advanced-cropper';
import vue3GoogleLogin from 'vue3-google-login';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Tooltip from 'primevue/tooltip';
import KeyFilter from 'primevue/keyfilter';


const app = createApp(App);
app.use(router);
app.use(store);

app.use(VueCookies);
app.use(VueAxios, axios);
app.use(VueZoomer)
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.use(PrimeVue, {
  theme: {
      preset: Aura,
      options: {
        darkModeSelector: false || 'none',
      }
  },
  locale: {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    dateFormat: 'dd/mm/yy',
    today: "Hoy",
    clear: "Limpiar",
  }
});
app.component('p-datepicker', PrimeVue.DatePicker);
app.use(Viewer);
app.component('Cropper', Cropper);
app.component('file-upload', VueUploadComponent)
app.component('VueDatePicker', VueDatePicker);
app.component('Multiselect', Multiselect);
app.directive('tooltip', Tooltip);
app.directive('keyfilter', KeyFilter);

app.mount('#app');
