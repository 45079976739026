import { createRouter, createWebHistory } from 'vue-router';
import Store from '../store/index'


const routes = [
    {
      path: '/inicio',
      component: () => import('@/pages/Home'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Baconer'],
      },
    },
    {
      path: '/mis-tickets',
      component: () => import('@/pages/Tickets'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Baconer'],
      },
    },
    {
      path: '/mis-facturas',
      component: () => import('@/pages/Invoices'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Baconer'],
      },
    },
    {
      path: '/fiscal',
      component: () => import('@/pages/Fiscal'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Baconer'],
      },
    },
    {
      path: '/config',
      component: () => import('@/pages/Config'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Baconer'],
      },
    },
    {
      path: '/config/planes',
      component: () => import('@/pages/Plans'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Baconer'],
      },
    },
    {
      path: '/apis',
      component: () => import('@/pages/Webservice'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['WebService'],
      },
    },
    {
      path: '/webhook',
      component: () => import('@/pages/Webhooks'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['WebService'],
      },
    },
    {
      path: '/apis/documentacion',
      component: () => import('@/pages/Documentation'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['WebService'],
      },
    },
    {
      path: '/soporte',
      component: () => import('@/pages/SupportTicket'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Support'],
      },
    },
    {
      path: '/ayuda',
      component: () => import('@/pages/Help'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['Baconer'],
      },
    },
    {
      path: '/',
      component: () => import('@/pages/Login'),
    },
    {
      path: '/registro',
      component: () => import('@/pages/Register'),
    },
    {
      path: '/resetear-contrasena',
      component: () => import('@/pages/PasswordReset'),
    },
    {
      path: '/confirmar-contrasena/:uid/:token',
      component: () => import('@/pages/NewPassword'),
    },
    {
      path: '/confirmar-email/:uid:key:token',
      component: () => import('@/pages/ConfirmEmail'),
    },
    {
        path: '/logout',
        component: () => import('@/pages/Logout'),
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('@/pages/Home'),
      meta: {
        requiresAuth: true,
      },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Store.getters['accounts/isLoggedIn']) {
      const profile = Store.getters['accounts/profile'];
      const userGroups = profile.groups || [];

      if (to.meta.allowedRoles && !to.meta.allowedRoles.some(role => userGroups.includes(role))) {
          next('/');
          return;
      }

      next();
      return;
    }
    next('/');
  } else if (to.matched.some((record) => record.meta.requiresAnonym)) {
    if (!Store.getters['accounts/isLoggedIn']) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
});

export default router